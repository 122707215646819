"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UF = exports.TIPO_CONTATO_EMAIL = exports.TIPO_CONTATO_2 = exports.TIPO_CONTATO_1 = exports.RACA = exports.PROFISSAO = exports.PESO = exports.PAIS = exports.PACIENTE = exports.NUMERO = exports.NOME_MAE = exports.NOME = exports.MUNICIPIO = exports.LOGRADOURO = exports.IDADE = exports.GRUPO_SANGUINEO = exports.GENERO = exports.FATOR_RH = exports.EMAIL = exports.DATA_NASCIMENTO = exports.CPF = exports.CONTATO_2 = exports.CONTATO_1 = exports.CNS = exports.CEP = exports.BAIRRO = exports.ALTURA = void 0;
var PACIENTE = 'paciente';
exports.PACIENTE = PACIENTE;
var NOME = 'nome';
exports.NOME = NOME;
var NOME_MAE = 'nomeMae';
exports.NOME_MAE = NOME_MAE;
var RACA = 'raca';
exports.RACA = RACA;
var CPF = 'cpf';
exports.CPF = CPF;
var CNS = 'cns';
exports.CNS = CNS;
var DATA_NASCIMENTO = 'dataNascimento';
exports.DATA_NASCIMENTO = DATA_NASCIMENTO;
var IDADE = 'idade';
exports.IDADE = IDADE;
var PESO = 'peso';
exports.PESO = PESO;
var ALTURA = 'altura';
exports.ALTURA = ALTURA;
var GENERO = 'genero';
exports.GENERO = GENERO;
var PROFISSAO = 'profissao';
exports.PROFISSAO = PROFISSAO;
var LOGRADOURO = 'logradouro';
exports.LOGRADOURO = LOGRADOURO;
var NUMERO = 'numero';
exports.NUMERO = NUMERO;
var BAIRRO = 'bairro';
exports.BAIRRO = BAIRRO;
var CEP = 'cep';
exports.CEP = CEP;
var PAIS = 'pais';
exports.PAIS = PAIS;
var UF = 'uf';
exports.UF = UF;
var MUNICIPIO = 'municipio';
exports.MUNICIPIO = MUNICIPIO;
var TIPO_CONTATO_1 = 'tipoContato1';
exports.TIPO_CONTATO_1 = TIPO_CONTATO_1;
var CONTATO_1 = 'contato1';
exports.CONTATO_1 = CONTATO_1;
var TIPO_CONTATO_2 = 'tipoContato2';
exports.TIPO_CONTATO_2 = TIPO_CONTATO_2;
var CONTATO_2 = 'contato2';
exports.CONTATO_2 = CONTATO_2;
var EMAIL = 'email';
exports.EMAIL = EMAIL;
var TIPO_CONTATO_EMAIL = 'tipoContatoEmail';
exports.TIPO_CONTATO_EMAIL = TIPO_CONTATO_EMAIL;
var GRUPO_SANGUINEO = 'grupoSanguineo';
exports.GRUPO_SANGUINEO = GRUPO_SANGUINEO;
var FATOR_RH = 'fatorRh';
exports.FATOR_RH = FATOR_RH;