import React, { useRef, memo, useContext, lazy, Suspense } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    SttButton,
    SttGrid,
    SttFileSelect,
    SttHeading,
    SttTranslateHook,
    SttCircularProgress,
    SttInput
} from '@stt-componentes/core';
import {
    Formik,
    Field,
    FastField,
    FieldArray
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../request';
import alerta from "../../signals/alerta";
import { MYME_TYPE_PERMITIDOS } from "../../common/Constants";
import carregando from '../../signals/carregando';

const validationSchema = (strings) => {
    return yup.object().shape({
        'folhaAutorizacao': yup.array().of(
            yup
                .mixed()
                .nullable()
                .test('arquivoObrigatorio', strings.campoObrigatorio, (value) => {
                    if (!(value instanceof File)) {
                        return false;
                    }

                    return true;
                })
                .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
                    if (!value) {
                        return true;
                    }

                    //Na alteração, isso ocorre
                    if (value && (!value.type || value.type == '')) {
                        return true;
                    }
                    return value && MYME_TYPE_PERMITIDOS.includes(value.type);
                })
        ),
        'instituicao': yup.object().shape({
            id: yup.number()
        }).required(strings.campoObrigatorio).nullable(),
        'numeroSisreg': yup
            .string()
            .nullable()
            .required(strings.campoObrigatorio),
        'numeroAutorizacaoControleAC': yup
            .string()
            .nullable()
            .required(strings.campoObrigatorio)
    })
}


const useStyles = makeStyles(theme => ({
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },
    divExternaImagens: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        overflowY: 'hidden'
    },
    botaoMenos: {
        minWidth: 0
    },
    container: {
        marginBottom: 0
    },
    button: {
        marginBottom: theme.spacing(1)
    }
}));

const FileSelect = memo((props) => {
    return (
        <SttFileSelect {...props} headers={getHeaders()} />
    )
});

const SttInstituicao = lazy(() => import('@stt-componentes/instituicao'));

const Autorizar = ({ callback, callbackSucesso, id, outrosDados }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const schema = validationSchema(strings);
    const formRef = useRef();
    const { outroProcedimento, instituicaoExecutora } = outrosDados.value;

    const initialValues = {
        folhaAutorizacao: [{}],
        instituicao: instituicaoExecutora || null,
        numeroSisreg: null,
        numeroAutorizacaoControleAC: null,
    };

    const validarAnexo = (value) => {
        let error;

        if (!value || !(value instanceof File)) {
            return null;
        }
        if (value.type !== 'image/jpeg' && value.type !== 'image/png' && value.type !== 'application/pdf' && value.type !== 'application/msword' &&
            value.type !== 'application/vnd.ms-excel' && value.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return strings.formatoArquivoInvalido;
        }
        return error;
    }

    const closeAlerta = (resetForm) => {
        alerta.value = {
            ...alerta.value,
            open: false
        }
        resetForm({ values: initialValues });
        callbackSucesso();
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dados, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                carregando.value = {
                    open: true,
                    text: strings.carregando
                }

                const formData = new FormData();
                formData.append('id', id);

                const dadosAnexos = [];
                dados.folhaAutorizacao.forEach((anexo, index) => {
                    if (anexo && (anexo instanceof File)) {
                        dadosAnexos.push(
                            {
                                id: anexo.id,
                                nome: anexo.name
                            }
                        );
                        formData.append(`folhaAutorizacao.${index}`, anexo);
                    }
                });
                formData.append('dadosAnexosFolhaAutorizacao', JSON.stringify(dadosAnexos));

                formData.append('numeroSisreg', dados.numeroSisreg);
                formData.append('numeroAutorizacaoControleAC', dados.numeroAutorizacaoControleAC);
                formData.append('idInstituicaoExecutora', dados.instituicao.id);
                formData.append('outroProcedimento', outroProcedimento);

                const APAC_API_BASE_URL = global.gConfig.url_base_apac;

                axios.post(`${APAC_API_BASE_URL}/operacao/autorizar`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
                    .then((response) => {
                        const { data } = response;
                        if (response.status === HttpStatus.OK) {
                            alerta.value = {
                                open: true,
                                title: strings.sucesso,
                                type: 'success',
                                message: data.message ? data.message : strings.sucessoOperacao,
                                options: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            closeAlerta(resetForm);
                                        }
                                    }

                                ],
                                onClose: () => {
                                    closeAlerta(resetForm);
                                }
                            }

                        }
                    })
                    .catch(err => {
                        const { response } = err;
                        let mensagem = strings.mensagemErroGeral;

                        if (response) {
                            if (response.status === HttpStatus.BAD_REQUEST) {
                                const { data } = response;
                                let arrMensagem = [];
                                data.errors.forEach(error => {
                                    arrMensagem.push(`- ${error.message}`);
                                });
                                if (arrMensagem) {
                                    mensagem = arrMensagem.join('\n');
                                }
                            }
                        }
                        alerta.value = {
                            open: true,
                            title: strings.erro,
                            type: 'error',
                            message: mensagem,
                            options: [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        }
                                    }
                                }

                            ],
                            onClose: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    })
                    .finally(() => {
                        setSubmitting(false);
                        carregando.value = {
                            open: false,
                            text: ''
                        }
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                    errors,
                    values
                    , ...formikEvents
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3} className={classes.container}>
                                <SttGrid item xs={12} sm={6}>
                                    <SttHeading required variant="h4" color="primary">{strings.instituicaoExecutora}</SttHeading>
                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                        <SttInstituicao
                                            fieldName={'instituicao'}
                                            headers={getHeaders()}
                                            strings={strings}
                                            obrigatorio={true}
                                            formExterno={{
                                                instituicao: values.instituicao,
                                                ...formikEvents
                                            }}
                                        />
                                    </Suspense>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6}>
                                    <SttHeading variant="h4" color="primary" required>{strings.folhaAutorizacao}</SttHeading>
                                    <FieldArray name="folhaAutorizacao" render={
                                        ({ remove, push }) => (
                                            <>
                                                <div className={classes.divExternaImagens}>
                                                    {
                                                        values.folhaAutorizacao.map((anexo, indice) => (
                                                            <Field key={indice} name={`folhaAutorizacao.${indice}`} validate={validarAnexo}>
                                                                {({
                                                                    field: { name, value },
                                                                    form: { setFieldValue },
                                                                    meta
                                                                }) => (
                                                                    <>
                                                                        <FileSelect
                                                                            onFileChange={(event) => {
                                                                                //Caso a imagem tenha sido excluída, remove a mesma do array de imagens no formulário e retira do array de imagens
                                                                                if (!event) {
                                                                                    if (values.folhaAutorizacao.length > 1) {
                                                                                        remove(indice);
                                                                                        return;
                                                                                    }
                                                                                    setFieldValue(`folhaAutorizacao[${indice}]`, {});
                                                                                    return;
                                                                                }

                                                                                if (event.target.files[0]) {
                                                                                    //Seta o valor da imagem no campo correspondente do formulário
                                                                                    setFieldValue(`folhaAutorizacao[${indice}]`, event.target.files[0]);
                                                                                }
                                                                            }}
                                                                            file={value}
                                                                            inputprops={{
                                                                                name: name,
                                                                                label: strings.formatosArquivo,
                                                                                value: value?.name,
                                                                                error: meta.touched && meta.error ? meta.error : undefined
                                                                            }}
                                                                            accept={["image/*", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "application/pdf", 'application/vnd.ms-excel']}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Field>
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    false &&
                                                    <>
                                                        <SttButton
                                                            className={classes.botaoMais}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                let chave = (values.folhaAutorizacao.length + 1);
                                                                let obj = {};
                                                                obj[chave] = {};
                                                                push({});
                                                            }}
                                                        >
                                                            +
                                                        </SttButton>
                                                        <SttButton
                                                            className={classes.botaoMenos}
                                                            variant="contained"
                                                            disabled={values.folhaAutorizacao.length === 1}
                                                            onClick={() => {
                                                                if (values.folhaAutorizacao.length > 1) {
                                                                    let indice = values.folhaAutorizacao.length - 1;
                                                                    remove(indice);
                                                                }
                                                            }}
                                                            color="primary">
                                                            -
                                                        </SttButton>
                                                    </>
                                                }

                                            </>
                                        )}
                                    />
                                </SttGrid>
                                <SttGrid item xs={12} sm={6}>
                                    <FastField name={'numeroSisreg'}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                {...field}
                                                required
                                                label={strings.numeroSolicitacaoSisreg}
                                                error={meta.touched && meta.error ? true : false}
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>

                                <SttGrid item xs={12} sm={6}>
                                    <FastField name={'numeroAutorizacaoControleAC'}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                {...field}
                                                required
                                                label={strings.numeroAutorizacaoControleAC}
                                                error={meta.touched && meta.error ? true : false}
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                            </SttGrid>
                            <SttGrid container spacing={2}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        className={classes.button}
                                        disabled={isSubmitting}
                                    >
                                        {strings.confirmar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                            callback();
                                        }}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default Autorizar;