import React, { useContext } from 'react';

import {
    SttModal, SttTranslateHook
} from '@stt-componentes/core';

import { TIPO_OPERACAO } from '../../common/Constants';
import Cancelamento from './cancelar';
import Devolucao from './devolver';
import Autorizacao from './autorizar';
import Aprovar from './aprovar';
import Invalidacao from './invalidar';
import EncaminharAutorizador from './encaminharAutorizador';
import { useSignals } from '@preact/signals-react/runtime';

const Operacoes = (props) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const {
        id,
        tipoOperacao,
        open,
        outrosDados,
        voltarPesquisa
    } = props;

    const callback = () => {
        open.value = !open.value;
    }

    const callbackSucesso = () => {
        callback();
        if(voltarPesquisa) {
            voltarPesquisa(true);
        }
    }

    const switchOperacao = () => {
        switch (tipoOperacao.value) {
            case TIPO_OPERACAO.CANCELAMENTO:
                return <Cancelamento
                    id={id}
                    callback={callback}
                    callbackSucesso={callbackSucesso}
                />

            case TIPO_OPERACAO.DEVOLUCAO:
                return <Devolucao
                    id={id}
                    callback={callback}
                    callbackSucesso={callbackSucesso}
                    outrosDados={outrosDados}
                />

            case TIPO_OPERACAO.AUTORIZACAO:
                return <Autorizacao
                    id={id}
                    callback={callback}
                    callbackSucesso={callbackSucesso}
                    outrosDados={outrosDados}
                />

            case TIPO_OPERACAO.ENCAMINHAR_AUTORIZADOR:
                return <EncaminharAutorizador
                    id={id}
                    callback={callback}
                    callbackSucesso={callbackSucesso}
                    outrosDados={outrosDados}
                />

            case TIPO_OPERACAO.APROVACAO:
                return <Aprovar
                    id={id}
                    callback={callback}
                    callbackSucesso={callbackSucesso}
                    outrosDados={outrosDados}
                />

                case TIPO_OPERACAO.INVALIDACAO:
                    return <Invalidacao
                        id={id}
                        callback={callback}
                        callbackSucesso={callbackSucesso}
                        outrosDados={outrosDados}
                    />
        }
    }

    const retornarTituloModal = () => {
        switch (tipoOperacao.value) {
            case TIPO_OPERACAO.CANCELAMENTO:
                return strings.cancelamento;

            case TIPO_OPERACAO.DEVOLUCAO:
                return strings.devolucao;

            case TIPO_OPERACAO.AUTORIZACAO:
                return strings.autorizacao;

            case TIPO_OPERACAO.ENCAMINHAR_AUTORIZADOR:
                return outrosDados.value.funcionarioAutorizador ? strings.alterarAutorizador : strings.encaminharAutorizador;

            case TIPO_OPERACAO.APROVACAO:
                return strings.aprovar;

            case TIPO_OPERACAO.INVALIDACAO:
                return strings.invalidar;
        }
    }

    return (
        <>
            {
                <SttModal
                    title={retornarTituloModal()}
                    open={open.value}
                    maxWidth="md"
                    outModalClose={callback}
                    iconClose={callback}
                    fullWidth={true}
                    children={
                        switchOperacao()
                    }
                />
            }
        </>
    );
}
export default Operacoes;