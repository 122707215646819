"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PARAMETROS_BUSCA = exports.GRUPOS_SANGUINEOS = exports.FATORES_RH = exports.CONTATO = void 0;
var CONTATO = {
  CATEGORIA: {
    TELEFONE_RESIDENCIAL: 1,
    TELEFONE_CELULAR: 2,
    TELEFONE_COMERCIAL: 3,
    EMAIL: 4,
    FAX: 5,
    OUTRO: 6
  }
};
exports.CONTATO = CONTATO;
var PARAMETROS_BUSCA = {
  NOME: 'BuscaPaciente_nome',
  CPF: 'BuscaPaciente_cpf',
  CNS: 'BuscaPaciente_cns'
};
exports.PARAMETROS_BUSCA = PARAMETROS_BUSCA;
var GRUPOS_SANGUINEOS = ['A', 'B', 'AB', 'O'];
exports.GRUPOS_SANGUINEOS = GRUPOS_SANGUINEOS;
var FATORES_RH = ['+', "-"];
exports.FATORES_RH = FATORES_RH;