import { estadoInicial } from "../componentes/pesquisa/apac/estadoInicial";
const { signal } = require("@preact/signals-react");

const pesquisarProcessos = signal(false);
const filtrosIniciais = estadoInicial();
const historicoPaciente = signal([]);
const parametrosHistoricoPaciente = signal({});

export const filtros = signal(filtrosIniciais);
export const resetFiltros = () => {
    filtros.value = filtrosIniciais;
}

export { pesquisarProcessos, filtrosIniciais, historicoPaciente, parametrosHistoricoPaciente };