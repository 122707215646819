import * as yup from 'yup';
import {
    DATA_INICIAL_SOLICITACAO,
    DATA_FINAL_SOLICITACAO,
    DATA_INICIAL_AUTORIZACAO,
    DATA_FINAL_AUTORIZACAO,
    NOME_INSTITUICAO_SOLICITANTE,
    NOME_PACIENTE
} from './fieldNames';

const hoje = new Date();
hoje.setHours(23, 59, 0, 0);

export default (strings) => {
    return yup.object().shape({
        [DATA_INICIAL_SOLICITACAO]: yup
            .date()
            .typeError(strings.dataInvalida)
            .max(hoje, strings.erroDataFutura)
            .nullable(),
        [DATA_FINAL_SOLICITACAO]: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(yup.ref(DATA_INICIAL_SOLICITACAO), strings.dataFinalAnteriorDataInicial)
            .max(new Date(), strings.erroDataFutura)
            .nullable(),
        [DATA_INICIAL_AUTORIZACAO]: yup
            .date()
            .typeError(strings.dataInvalida)
            .max(hoje, strings.erroDataFutura)
            .nullable(),
        [DATA_FINAL_AUTORIZACAO]: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(yup.ref(DATA_INICIAL_AUTORIZACAO), strings.dataFinalAnteriorDataInicial)
            .max(new Date(), strings.erroDataFutura)
            .nullable(),
        [NOME_PACIENTE]: yup
            .string()
            .max(100)
            .test('nome', strings.nomePacienteSomenteLetras, val => {
                if (val && !(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/.test(val))) {
                    return false;
                }
                return true;
            })
            .nullable(),
        [NOME_INSTITUICAO_SOLICITANTE]: yup
            .string()
            .max(100)
            .test('nome', strings.nomeSolicitanteSomenteLetras, val => {
                if (val && !(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/.test(val))) {
                    return false;
                }
                return true;
            })
            .nullable()
    })
}