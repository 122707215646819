const temPerfilRBAC = (usuario, perfil) => {
    if (!usuario.value) {
        return false;
    }
    if (!usuario.value.perfisRBAC || !perfil) {
        return false;
    }
    if (Array.isArray(perfil)) {
        return perfil.some(p => usuario.value.perfisRBAC.some(prbac => prbac.identificador === p));
    }
    return usuario.value.perfisRBAC.some(p => p.identificador === perfil);
};

const temPermissaoRBAC = (usuario, permissao) => {
    if (!usuario.value) {
        return false;
    }
    if (!usuario.value.permissoesRBAC || !permissao) {
        return false;
    }
    if (Array.isArray(permissao)) {
        return permissao.some(p => usuario.value.permissoesRBAC.includes(p));
    }
    return usuario.value.permissoesRBAC.includes(permissao);
};

export { 
    temPerfilRBAC, 
    temPermissaoRBAC 
};

