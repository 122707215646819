"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var yup = _interopRequireWildcard(require("yup"));
var _jsBrasil = require("js-brasil");
var _fieldNames = require("./fieldNames");
var _constantes = require("../constantes");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _default = function _default(strings, campos, cadastroBasico) {
  var _yup$object$shape;
  var esquemaInterno = yup.object().shape((_yup$object$shape = {}, _defineProperty(_yup$object$shape, _fieldNames.NOME, yup.string().trim().min(1).max(100).matches(/(\w.+\s).+/i, strings.nomePacienteInvalido).nullable().required(strings.campoObrigatorio)), _defineProperty(_yup$object$shape, _fieldNames.CPF, yup.string().when(_fieldNames.CNS, {
    is: function is(val) {
      return !val || val.length === 0;
    },
    then: yup.string().nullable().test('cpf-valido', strings.cpfInvalido, function (cpf) {
      cpf = cpf || '';
      if (cpf.length === 0) {
        return false;
      }
      return _jsBrasil.validateBr.cpf(cpf.trim());
    }).required(strings.campoObrigatorio),
    otherwise: yup.string().nullable().test('cpf-valido', strings.cpfInvalido, function (cpf) {
      cpf = cpf || '';
      if (cpf.length === 0) {
        return true;
      }
      return _jsBrasil.validateBr.cpf(cpf.trim());
    })
  })), _defineProperty(_yup$object$shape, _fieldNames.CNS, yup.string().when(_fieldNames.CPF, {
    is: function is(val) {
      return !val || val.length === 0;
    },
    then: yup.string().nullable().test('cnf-valido', strings.cnsInvalido, function (cns) {
      cns = cns || '';
      if (cns.length === 0) {
        return false;
      }
      return _jsBrasil.validateBr.cns(cns.trim());
    }).required(strings.campoObrigatorio),
    otherwise: yup.string().nullable().test('cnf-valido', strings.cnsInvalido, function (cns) {
      cns = cns || '';
      if (cns.length === 0) {
        return true;
      }
      return _jsBrasil.validateBr.cns(cns.trim());
    })
  })), _defineProperty(_yup$object$shape, _fieldNames.DATA_NASCIMENTO, yup.date().typeError(strings.dataInvalida).nullable().required(strings.campoObrigatorio).max(new Date(), strings.erroDataFutura)), _defineProperty(_yup$object$shape, _fieldNames.GENERO, yup.object().shape({
    id: yup.number(),
    descricao: yup.string(),
    sigla: yup.string()
  }).nullable().required(strings.campoObrigatorio)), _yup$object$shape), [[_fieldNames.CPF, _fieldNames.CNS]]).required();
  var gruposCampos = campos;
  var temTipoContato1 = false;
  var temContato1 = false;
  var temContato2 = false;
  var contato1Obrigatorio = false;
  var contato2Obrigatorio = false;
  if (!Array.isArray(campos)) {
    gruposCampos = campos.camposCadastro;
  }
  gruposCampos.forEach(function (grupo) {
    grupo.forEach(function (campo) {
      // CAMPOS UTILIZADOS NAS TELAS DE DERMATO, OU TELECONSULTORIAS, OU TFD, ETC.
      if (!cadastroBasico) {
        // PROFISSÃO
        if (campo.nome === _fieldNames.PROFISSAO && campo.obrigatorio) {
          esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.PROFISSAO, yup.string().trim().min(1, strings.minimo1Caracter).max(255, strings.maximo255Caracteres).matches(/^[A-Za-z].+$/, strings.profissaoInvalida).required(strings.campoObrigatorio))));
        }
        // PESO
        if (campo.nome === _fieldNames.PESO) {
          var regraPeso = yup.number().positive(strings.maiorZero).lessThan(201, strings.pesoMenorIgualDuzentos);
          if (campo.obrigatorio) {
            regraPeso = regraPeso.required(strings.campoObrigatorio);
          }
          esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.PESO, regraPeso)));
        }
        // ALTURA
        if (campo.nome === _fieldNames.ALTURA) {
          var regraAltura = yup.number().positive(strings.maiorZero).lessThan(301, strings.alturaMenorIgualTrezentos);
          if (campo.obrigatorio) {
            regraAltura = regraAltura.required(strings.campoObrigatorio);
          }
          esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.ALTURA, regraAltura)));
        }
        // GRUPO SANGUÍNEO
        if (campo.nome === _fieldNames.GRUPO_SANGUINEO) {
          var regraGrupoSanguineo = yup.string().nullable();
          if (campo.obrigatorio) {
            regraGrupoSanguineo = regraGrupoSanguineo.required(strings.campoObrigatorio);
          }
          esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.GRUPO_SANGUINEO, regraGrupoSanguineo)));
        }
        // FATOR RH
        if (campo.nome === _fieldNames.FATOR_RH) {
          var regraFatorRh = yup.string().nullable();
          if (campo.obrigatorio) {
            regraFatorRh = regraFatorRh.required(strings.campoObrigatorio);
          }
          esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.FATOR_RH, regraFatorRh)));
        }
        // IDADE
        if (campo.nome === _fieldNames.IDADE) {
          esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.IDADE, yup.string())));
        }
      }
      // CAMPOS DO CADASTRO BÁSICO

      // NOME DA MÃE
      if (campo.nome === _fieldNames.NOME_MAE) {
        var regraMomeMae = yup.string().trim().min(1).max(100).matches(/(\w.+\s).+/i, strings.nomePacienteInvalido);
        if (campo.obrigatorio) {
          regraMomeMae = regraMomeMae.required(strings.campoObrigatorio);
        }
        esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.NOME_MAE, regraMomeMae)));
      }
      // CEP
      if (campo.nome === _fieldNames.CEP && campo.obrigatorio) {
        esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.CEP, yup.string().trim().required(strings.campoObrigatorio))));
      }
      // LOGRADOURO
      if (campo.nome === _fieldNames.LOGRADOURO && campo.obrigatorio) {
        esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.LOGRADOURO, yup.string().trim().min(1, strings.minimo1Caracter).max(255, strings.maximo255Caracteres).required(strings.campoObrigatorio))));
      }

      // NÚMERO
      if (campo.nome === _fieldNames.NUMERO && campo.obrigatorio) {
        esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.NUMERO, yup.string().max(10, strings.maximo10Caracteres).required(strings.campoObrigatorio))));
      }
      // BAIRRO
      if (campo.nome === _fieldNames.BAIRRO && campo.obrigatorio) {
        esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.BAIRRO, yup.string().trim().min(1, strings.minimo1Caracter).max(30, strings.maximo30Caracteres).required(strings.campoObrigatorio))));
      }
      // PAIS
      if (campo.nome === _fieldNames.PAIS) {
        var regraPais = yup.object().shape({
          id: yup.number(),
          nome: yup.string(),
          sigla: yup.string()
        }).nullable();
        if (campo.obrigatorio) {
          regraPais = regraPais.required(strings.campoObrigatorio);
        }
        esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.PAIS, regraPais)));
      }
      // UF
      if (campo.nome === _fieldNames.UF) {
        var regraUf = yup.object().shape({
          id: yup.number(),
          nome: yup.string(),
          sigla: yup.string(),
          id_pais: yup.number(),
          codigo_ibge: yup.string()
        }).nullable();
        if (campo.obrigatorio) {
          regraUf = regraUf.required(strings.campoObrigatorio);
        }
        esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.UF, regraUf)));
      }
      // MUNICÍPIO
      if (campo.nome === _fieldNames.MUNICIPIO) {
        var regraMunicipio = yup.object().shape({
          id: yup.number(),
          nome: yup.string(),
          id_estado: yup.number(),
          codigo_ibge: yup.string(),
          nome_estado: yup.string(),
          latitude: yup.number(),
          longitude: yup.number()
        }).nullable();
        if (campo.obrigatorio) {
          regraMunicipio = regraMunicipio.required(strings.campoObrigatorio);
        }
        esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.MUNICIPIO, regraMunicipio)));
      }
      // RACA
      if (campo.nome === _fieldNames.RACA) {
        var regraRaca = yup.object().shape({
          id: yup.number(),
          descricao: yup.string()
        }).nullable();
        if (campo.obrigatorio) {
          regraRaca = regraRaca.required(strings.campoObrigatorio);
        }
        esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.RACA, regraRaca)));
      }

      // CONTATOS
      if (campo.nome === _fieldNames.TIPO_CONTATO_1) {
        temTipoContato1 = true;
      }
      if (campo.nome === _fieldNames.CONTATO_1) {
        temContato1 = true;
        contato1Obrigatorio = campo.obrigatorio;
      }
      if (campo.nome === _fieldNames.CONTATO_2) {
        temContato2 = true;
        contato2Obrigatorio = campo.obrigatorio;
      }
      if (campo.nome === _fieldNames.EMAIL) {
        var regraEmail = yup.string().email(strings.emailInvalido).nullable();
        if (campo.obrigatorio) {
          regraEmail = regraEmail.required(strings.campoObrigatorio);
        }
        esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.EMAIL, regraEmail)));
      }
    });
  });
  if (temTipoContato1) {
    var regraContato1 = yup.string().when(_fieldNames.TIPO_CONTATO_1, {
      is: function is(val) {
        return val === _constantes.CONTATO.CATEGORIA.TELEFONE_CELULAR;
      },
      then: yup.string().test('celular1-valido', strings.numeroCelularInvalido, function (celular) {
        if (!celular) {
          return true;
        }
        var digitos = celular.replace(/\D/g, '');
        if (digitos.length < 11) {
          // 9 dígitos + DDD
          return false;
        }
        return true;
      }),
      otherwise: yup.string().test('telefone1-valido', strings.numeroTelefoneInvalido, function (telefone) {
        if (!telefone) {
          return true;
        }
        var digitos = telefone.replace(/\D/g, '');
        if (digitos.length < 10) {
          // 8 dígitos + DDD
          return false;
        }
        return true;
      })
    });
    if (contato1Obrigatorio) {
      regraContato1 = regraContato1.required(strings.campoObrigatorio);
    }
    esquemaInterno = esquemaInterno.concat(yup.object().shape(_defineProperty({}, _fieldNames.CONTATO_1, regraContato1)));
  }
  if (!temTipoContato1 && temContato1 && temContato2) {
    var _yup$object$shape19;
    esquemaInterno = esquemaInterno.concat(yup.object().shape((_yup$object$shape19 = {}, _defineProperty(_yup$object$shape19, _fieldNames.CONTATO_1, yup.string().test('telefone1-valido', strings.numeroTelefoneInvalido, function (telefone) {
      if (!telefone) {
        return true;
      }
      var digitos = telefone.replace(/\D/g, '');
      if (digitos.length < 10) {
        // 8 dígitos + DDD
        return false;
      }
      return true;
    }).test('telefoneObrigatorio', strings.umTelefoneObrigatorio, function () {
      if (contato1Obrigatorio || contato2Obrigatorio) {
        return this.parent[_fieldNames.CONTATO_1] || this.parent[_fieldNames.CONTATO_2];
      }
      return true;
    })), _defineProperty(_yup$object$shape19, _fieldNames.CONTATO_2, yup.string().test('celular1-valido', strings.numeroCelularInvalido, function (celular) {
      if (!celular) {
        return true;
      }
      var digitos = celular.replace(/\D/g, '');
      if (digitos.length < 11) {
        // 9 dígitos + DDD
        return false;
      }
      return true;
    }).test('telefoneObrigatorio', strings.umTelefoneObrigatorio, function () {
      if (contato1Obrigatorio || contato2Obrigatorio) {
        return this.parent[_fieldNames.CONTATO_1] || this.parent[_fieldNames.CONTATO_2];
      }
      return true;
    })), _yup$object$shape19)));
  }
  return esquemaInterno;
};
exports["default"] = _default;