import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { SttCircularProgress } from '@stt-componentes/core';
import PesquisaApac from './pesquisa/apac';
import { SttCacheManager } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import { usuario } from '../../signals';
import { useSignals } from '@preact/signals-react/runtime';
import ProtectedRoute from './protectedRoute';
import Menu from '../../componentes/menu';
import Erro401 from '../erro/401';
import { PERMISSOES } from '../../common/Constants';
import SolicitacaoApac from './solicitacao/apac';
import AdministrativoApac from './administrativo';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Interno = () => {
    useSignals();
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div>
            {
                usuario.value.autenticado
                    ?
                    <div className={classes.wrapper}>
                        <Menu/>
                        <Routes>
                            <Route path="/nao-autorizado" exact element={<Erro401 />} />
                            <Route path='/administrativo' exact element={<ProtectedRoute component={AdministrativoApac} permissao={PERMISSOES.ADMINISTRACAO} />} />
                            <Route path='/solicitacao' exact element={<ProtectedRoute component={SolicitacaoApac} permissao={PERMISSOES.PESQUISAR} />} />
                            <Route path='/' exact element={<ProtectedRoute component={PesquisaApac} permissao={PERMISSOES.PESQUISAR} />} />
                            <Route path="*" element={<Navigate replace to="/" />} />
                        </Routes>
                    </div>
                    :
                    <>
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                        <SttCacheManager config={global.gConfig} onSync={loaded => {
                            usuario.value = { ...Cache.getUserInfo(), autenticado: loaded };
                            navigate(location);
                        }} />
                    </>  
            }
        </div>
    );

};

export default Interno;
