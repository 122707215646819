import React, { useRef, useState, useEffect, useContext } from "react";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttInput,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FastField,
    Field
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    MOTIVO,
    JUSTIFICATIVA
} from './fieldNames';
import { useSignal } from "@preact/signals-react";
import alerta from "../../signals/alerta";
import { CODIGO_PET_SCAN, situacao } from "../../common/Constants";
import carregando from '../../signals/carregando';

const validationSchema = (strings) => {
    return yup.object().shape({
        [MOTIVO]: yup
            .object().shape({
                id: yup.number(),
                descricao: yup.string(),
                exige_complemento: yup.string(),
                etapa_fluxo: yup.string(),
            })
            .nullable()
            .required(strings.campoObrigatorio)
    });
}

const OperacaoDevolver = ({ callback, callbackSucesso, id, outrosDados }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const APAC_API_BASE_URL = global.gConfig.url_base_apac;
    const { procedimento, outroProcedimento, situacaoProcesso } = outrosDados.value;

    const initialValues = {
        justificativa: procedimento?.codigo === CODIGO_PET_SCAN ? strings.motivoDevolucaoPadrao : '',
        motivo: null
    };

    const motivosDevolucao = useSignal(null);

    useEffect(() => {
        axios
            .get(`${APAC_API_BASE_URL}/administrativo/motivo-devolucao`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    motivosDevolucao.value = itens;
                }
            })
            .catch(err => {
                motivosDevolucao.value = [];
                alerta.value = {
                    open: true,
                    title: strings.erro,
                    type: 'error',
                    message: strings.erroRecuperacaoMotivoDevolucao,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }

                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }
            });
    }, []);

    const closeAlerta = (resetForm) => {
        alerta.value = {
            ...alerta.value,
            open: false
        }
        resetForm({ values: initialValues });
        callbackSucesso();
    }

    const finalizarDevolucao = ({ url, dataPost, setSubmitting, resetForm }) => {
        setSubmitting(true);
        axios.post(url, dataPost, { headers: getHeaders() })
            .then((response) => {

                const { data } = response;
                if (response.status === HttpStatus.OK) {
                    alerta.value = {
                        open: true,
                        title: strings.sucesso,
                        type: 'success',
                        message: data.message ? data.message : strings.sucessoOperacao,
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    closeAlerta(resetForm);
                                }
                            }

                        ],
                        onClose: () => {
                            closeAlerta(resetForm);
                        }
                    }
                }
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.mensagemErroGeral;

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }
                alerta.value = {
                    open: true,
                    title: strings.erro,
                    type: 'error',
                    message: mensagem,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }

                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dataPost, { setSubmitting, resetForm }) => {
                dataPost.justificativa = dataPost.justificativa.trim().toUpperCase();
                dataPost.id = id;
                dataPost.outroProcedimento = outroProcedimento;
                let url;

                if (outroProcedimento && situacaoProcesso === situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR) {
                    // TEMPORÁRIO, será feito tratamento por alerta
                    url = `${APAC_API_BASE_URL}/operacao/devolver`;
                    finalizarDevolucao({url, dataPost, setSubmitting, resetForm});
                    return;
                    alerta.value = {
                        open: true,
                        title: strings.atencao,
                        type: 'alert',
                        message: strings.mensagemDevolucao,
                        options: [
                            {
                                title: strings.devolverSolicitante,
                                onClick: () => {
                                    url = `${APAC_API_BASE_URL}/operacao/devolver`;
                                    finalizarDevolucao({url, dataPost, setSubmitting, resetForm});
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                },
                            },
                            {
                                title: strings.devolverOperador,
                                onClick: () => {
                                    url = `${APAC_API_BASE_URL}/operacao/devolver-operador`
                                    finalizarDevolucao({url, dataPost, setSubmitting, resetForm});
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                }
                            }
                        ],
                        onClose: () => {
                            setSubmitting(false);
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }
                } else {
                    url = `${APAC_API_BASE_URL}/operacao/devolver`;
                    finalizarDevolucao({url, dataPost, setSubmitting, resetForm});
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    }
                }
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <Field name={MOTIVO}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue },
                                            meta,
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.motivo,
                                                    required: true,
                                                    error: meta.touched && meta.error ? meta.error : undefined
                                                }}
                                                getOptionLabel={option => (option && option.descricao) || ''}
                                                getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                options={motivosDevolucao.value}
                                                carregando={!motivosDevolucao.value}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => setFieldValue('motivo', item || null)}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12}>
                                    <FastField name={JUSTIFICATIVA}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                multiline
                                                rows={procedimento?.codigo === CODIGO_PET_SCAN ? 20 : 5}
                                                {...field}
                                                label={strings.justificativa}
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoMensagemEspera}
                                />
                            </SttGrid>
                            <SttGrid container spacing={2}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        disabled={isSubmitting}
                                    >
                                        {strings.confirmar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                            callback();
                                        }}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default OperacaoDevolver;