import * as yup from 'yup';
import {
    FIELDS
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [FIELDS.JUSTIFICATIVA]: yup.object().shape({
            [FIELDS.CID10_PRINCIPAL]: yup.array()
                .of(
                    yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string()
                    }).required()
                ).min(1, strings.campoObrigatorio),
            [FIELDS.JUSTIFICATIVA_PROCEDIMENTO]: yup.string()
                .test('justificativaOutros', strings.campoObrigatorio, function () {
                    const { from } = this;

                    if (from[1].value.procedimento?.outroProcedimento) {
                        return true;
                    }

                    return from[0].value.justificativaProcedimento?.trim();
                })
                .nullable(),
        })
    })
}