import React, { useEffect, useContext, memo } from 'react';
import Pesquisa from '../../../../componentes/pesquisa/apac';
import { SttTabs, SttTranslateHook } from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { abaAtiva } from '../../../../signals/util';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const Solicitacoes = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const abas = useSignal([]);

    const adicionarAba = (aba) => {
        let dadosAbas = [...abas.value];
        let { idAba } = aba;

        // Controle para evitar adição de aba repetida
        if (!dadosAbas.some(a => a.idAba === idAba)) {
            dadosAbas.push(aba);
            abas.value = dadosAbas;
        }
        
        setTimeout(() => {
            abaAtiva.value = dadosAbas.findIndex(a => a.idAba === idAba).toString();
        }, 120);
    };

    const removerAba = (indice) => {
        abaAtiva.value = '0';
        const i = indice[0];

        const dadosAbas = [
            ...abas.value.slice(0, i),
            ...abas.value.slice(i + 1)
        ];

        setTimeout(() => {
            abas.value = dadosAbas;
        }, 120);
    }

    const removerAbaId = (idAba) => {
        const index = abas.value.findIndex(a => a.idAba === idAba);
        if (index !== -1) {
            removerAba([index]);
        }
    }

    useEffect(() => {
        abas.value = [{
            titulo: strings.pesquisa,
            conteudo: Pesquisa,
            key: 'Pesquisa',
            adicionarAba,
            removerAba,
            removerAbaId,
            permanente: true,
        }];
    }, []);

    return (
        <SttTabs abas={abas.value}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={(abaNova) => abaAtiva.value = abaNova}
            abaAtiva={abaAtiva.value}
            handleCloseTab={(indice) => removerAba(indice)}
            permanente={true}
            exigeConfirmacaoClose={false}
        />
    );
};

export default memo(Solicitacoes);