import * as yup from 'yup';
import {
    FIELDS
} from './fieldNames';
import { MYME_TYPE_PERMITIDOS, TIPO_ANEXO_SIGLA, TIPO_SOLICITACAO } from '../../../../common/Constants';

const encontrouAnexo = (tipoAnexoSigla, from) => {
    return from[0].value.anexos.some(anexo => {
        return anexo.tipoAnexo?.sigla === tipoAnexoSigla && anexo.anexo instanceof File;
    });
}

export default (strings) => {
    return yup.object().shape({
        [FIELDS.ANEXOS]: yup.array().of(
            yup.object().shape({
                [FIELDS.ANEXO]: yup
                    .mixed()
                    .nullable()
                    .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
                        if (!value) {
                            return true;
                        }

                        if (value && (!value.type || value.type == '')) {
                            return true;
                        }
                        return value && MYME_TYPE_PERMITIDOS.includes(value.type);
                    }),
                [FIELDS.TIPO_ANEXO]: yup.object()
                    .shape({
                        id: yup.string(),
                        descricao: yup.string()
                    })
                    .nullable()
                    .required(strings.campoObrigatorio)

            }))
            // .test('Arquivos obrigatórios da nova APAC - Outros procedimentos', strings.anexosObrigatoriosNovaApacOutrosProcedimentos, function () {
            //     const { from } = this;

            //     if (from[0].value.procedimento.tipoSolicitacao === TIPO_SOLICITACAO.PADRAO && from[0].value.procedimento.outroProcedimento && from[0].value.procedimento.procedimento) {
            //         const tiposAnexosObrigatorios = [
            //             TIPO_ANEXO_SIGLA.HISTORICO_SGPE,
            //             TIPO_ANEXO_SIGLA.LAUDO_MEDICO,
            //             TIPO_ANEXO_SIGLA.EXAMES_OBRIGATORIOS,
            //             TIPO_ANEXO_SIGLA.SISREG,
            //             TIPO_ANEXO_SIGLA.DOCUMENTO_PESSOAL
            //         ];

            //         const encontrouTodosAnexos = tiposAnexosObrigatorios.every(tipo => encontrouAnexo(tipo, from));

            //         return encontrouTodosAnexos;
            //     }

            //     return true;
            // })
            // .test('Arquivos obrigatórios da nova APAC - PET CT', strings.anexosObrigatoriosNovaApacPETCT, function () {
            //     const { from } = this;

            //     if (from[0].value.procedimento.tipoSolicitacao === TIPO_SOLICITACAO.PADRAO && !from[0].value.procedimento.outroProcedimento && from[0].value.procedimento.procedimento) {
            //         const tiposAnexosObrigatorios = [
            //             TIPO_ANEXO_SIGLA.LAUDO_MEDICO,
            //             TIPO_ANEXO_SIGLA.EXAMES_OBRIGATORIOS,
            //             TIPO_ANEXO_SIGLA.SISREG,
            //             TIPO_ANEXO_SIGLA.DOCUMENTO_PESSOAL
            //         ];

            //         const encontrouTodosAnexos = tiposAnexosObrigatorios.every(tipo => encontrouAnexo(tipo, from));

            //         return encontrouTodosAnexos;
            //     }

            //     return true;
            // })
            // .test('Arquivos obrigatórios da APAC continuidade', strings.anexosObrigatoriosApacContinuidade, function () {
            //     const { from } = this;

            //     if (from[0].value.procedimento.tipoSolicitacao === TIPO_SOLICITACAO.CONTINUIDADE) {
            //         const tiposAnexosObrigatorios = [
            //             TIPO_ANEXO_SIGLA.LAUDO_MEDICO
            //         ];

            //         const encontrouTodosAnexos = tiposAnexosObrigatorios.every(tipo => encontrouAnexo(tipo, from));
            //         return encontrouTodosAnexos;
            //     }

            //     return true;
            // })
            .required(strings.campoObrigatorio)
    });
}
