import React, { lazy, memo, useContext, Suspense } from 'react';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { usuario } from '../../../signals/index';
import { useSignal, useSignals, useSignalEffect } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const AutorizadoresComplexosRegulatorios = lazy(() => import('../../../componentes/administrativo/autorizadoresComplexosRegulatorios'));

const VinculoAutorizadorComplexosRegulatorios = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <AutorizadoresComplexosRegulatorios {...props} />
        </Suspense>
    )
});

const AdministrativoApac = (props) => {
    useSignals();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const abas = useSignal([]);
    const abaAtiva = useSignal('0');

    useSignalEffect(() => {
        let dadosAbas = [
            {
                titulo: strings.vinculoAutorizadorComplexosRegulatorios,
                conteudo: VinculoAutorizadorComplexosRegulatorios,
                permanente: true,
                key: 'VinculoAutorizadorComplexosRegulatorios '
            }
        ];

        abas.value = dadosAbas;
    });

    return (
        <SttTabs abas={abas.value}
            className={classes.wrapper}
            abaAtiva={abaAtiva.value}
            exigeConfirmacaoClose={false}
        />
    );
}

export default AdministrativoApac;