import * as yup from 'yup';
import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import dadosPacienteSchema from '@stt-componentes/paciente/dist/lib/form/validationSchema';

export default (strings, campos) => {
    return yup.object().shape({
        [PACIENTE]: dadosPacienteSchema(strings, campos)
    });
}

