module.exports = {
    
    dataAcao: 'Data da Ação',
    dataUltimaAcao: 'Data da última ação',
    identificador: 'Identificador',
    integracaoExames: 'Integrações de Exames',
    mensagem: 'Mensagem',
    notificacao: 'Notificação',
    numeroExame: 'Número do Exame',
    status: 'Status',
    pacs_aetitle: 'PACS AE Title',
    tituloVisualizacao: 'Visualização de Histórico',
    urlPacs: 'URL do PACS',

};
