import { signal } from "@preact/signals-react";

const alerta = signal({
    open: false,
    title: '',
    type: 'alert',
    message: '',
    options: [],
    onClose: null
});

export default alerta;