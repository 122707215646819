import React from 'react';
import Interno from './interno';
import Erro from './erro';
import carregando from '../signals/carregando';
import alerta from '../signals/alerta';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SttAlerta, SttLoading } from '@stt-componentes/core';
import { useSignals } from '@preact/signals-react/runtime';

const Home = () => {
    useSignals();
    return (
        <BrowserRouter>
            {
                <div>
                    <Routes>
                        <Route path="/erro" exact element={<Erro />} />
                        <Route path="*" element={<Interno />}  />
                    </Routes>
                    <SttAlerta {...alerta.value} />
                    <SttLoading {...carregando.value}/>                    
                </div>
            }
        </BrowserRouter>
    );
};

export default Home;
