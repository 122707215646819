let dataInicial = new Date();
let dataFinal = new Date();
dataInicial.setDate(dataFinal.getDate() - 30);

export const estadoInicial = ()=> {
    return {
        sequencial: null,
        data_inicial_solicitacao: dataInicial,
        data_final_solicitacao: dataFinal,
        data_inicial_autorizacao: null,
        data_final_autorizacao: null,
        nome_paciente: null,
        cns_paciente: '',
        cpf_paciente: '',
        nome_funcionario_solicitante: null,
        nome_procedimento: null,
        codigo_procedimento: null,
        situacao: null,
        nome_funcionario_autorizador: null,
    }
}
