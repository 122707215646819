import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import Home from './paginas';
import { SttTranslateHook } from '@stt-componentes/core';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

class App extends Component {
	render() {
		return (
			<>
				<NotificationContainer />
				<ThemeProvider theme={theme}>
					<SttTranslateHook.I18nContextProvider modulo={'apac'}>
						<Home />
					</SttTranslateHook.I18nContextProvider>
				</ThemeProvider>
			</>
		)
	}

	getChildContext() {
		return {
			modulo: this.props.modulo,
			currentLanguage: this.props.currentLanguage
		};
	}
}

App.childContextTypes = {
	currentLanguage: PropTypes.string.isRequired,
	modulo: PropTypes.string.isRequired
};

export default App;