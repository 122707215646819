import * as yup from 'yup';
import {
    FIELDS
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [FIELDS.SOLICITANTE]: yup
            .object()
            .nullable()
            .required(strings.campoObrigatorio)
    });
}
