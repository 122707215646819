import { signal } from '@preact/signals-react';

export const procedimentos = signal([]);

export const procedimentoSelecionado = signal([])

export const valoresIniciais = signal(null);

export const instituicaoSolicitanteSigtap = signal([]);

export const instituicaoAutorizadoraSigtap = signal([]);

export const abaAtiva = signal('0');

export const recarregarPesquisa = signal(false);

export const buscarHistorico = signal(false);