'use strict';

const Wreck = require('@hapi/wreck');
const StringMask = require('string-mask');
const { TIPO_CONTATO, TIPOS_SENHA } = require('../constantes/index');
const { stringSimilarity } = require('string-similarity-js');

const Util = {
    verificarMaioridade: async (dataNascimento) => {
        const idade = await _geradorIdade(dataNascimento);
        return idade >= 18;
    },

    verificarSenha: async (senha) => {
        if (senha.length < 8) {
            return false;
        }

        if (senha.includes(' ') || /(.)\1/.test(senha)) {
            return false;
        }

        if (!/[A-Z]/.test(senha)) {
            return false;
        }

        if (!/\d/.test(senha)) {
            return false;
        }

        if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(senha)) {
            return false;
        }

        return true;
    },

    verificarSegurancaSenha: async (dados) => {
        const dadosValidacao = Object.values(dados);
        const erros = [];

        if (dados.id && !dados.senhaAtual) {
            erros.push('A senha é obrigatória.');
        }
        if (!dados.novaSenha || !dados.confirmacaoSenha) {
            erros.push('A nova senha e a confirmação da nova senha são obrigatórias.');
        }
        if (dados.novaSenha.length < 8) {
            erros.push('A nova senha deve conter mais de 8 caracteres.');
        }
        if (!/\d/.test(dados.novaSenha)) {
            erros.push('A nova senha deve conter ao menos um caractere numérico.');
        }
        if (!/[a-zA-Z]/.test(dados.novaSenha)) {
            erros.push('A nova senha deve conter ao menos um caractere alfabético.');
        }
        if (!/[a-z]/.test(dados.novaSenha)) {
            erros.push('A nova senha deve conter ao menos uma letra minúscula.');
        }
        if (!/[A-Z]/.test(dados.novaSenha)) {
            erros.push('A nova senha deve conter ao menos uma letra maiúscula.');
        }
        if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(dados.novaSenha)) {
            erros.push('A nova senha deve conter ao menos um caractere especial.');
        }
        if (dados.novaSenha !== dados.confirmacaoSenha) {
            erros.push('A senha e a confirmação da senha devem ser idênticas.');
        }

        const testeSimilaridade = (dado) => {
            let senha_formatada = dados.novaSenha?.replace(/\ /g, '');
            let limte_similaridade = 0.6;
            let similaridade = 0;
            let erroSimilaridade = [];

            if (dado === dados.nome) {
                senha_formatada = dados.novaSenha.replace(/\d/g, '').replace(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, '');
                limte_similaridade = 0.4;
                erroSimilaridade.push('A senha não pode conter o nome do usuário.');
            }
            if (dado === dados.dataNascimento || dado === dados.cpf) {
                senha_formatada = dados.novaSenha.replace(/[a-zA-Z]/g, '').replace(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, '');
                if (dado === dados.dataNascimento) {
                    erroSimilaridade.push('A senha não pode conter a data de nascimento do usuário.');
                }
                if (dado === dados.cpf) {
                    erroSimilaridade.push('A senha não pode conter o cpf do usuário.');
                }
            }
            if (dado === dados.login) {
                if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(dado)) {
                    senha_formatada = senha_formatada.replace(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, '');
                }
                if (!/[A-Za-z]/.test(dado)) {
                    senha_formatada = senha_formatada.replace(/[A-Za-z]/g, '');
                }
                if (!/\d/.test(dado)) {
                    senha_formatada = senha_formatada.replace(/\d/g, '');
                }
                erroSimilaridade.push('A senha não pode conter o login do usuário.');
            }
            if (dado === dados.email) {
                if (!/@.*\.com$/.test(senha_formatada)) {
                    dado = dado.replace(/@.*\.com$/, '');
                }
                erroSimilaridade.push('A senha não pode conter o email do usuário.');
            }
            similaridade = stringSimilarity(senha_formatada, dado);

            if (similaridade >= limte_similaridade) {
                erroSimilaridade.forEach((erro) => {
                    erros.push(erro);
                });
            }
        };

        dadosValidacao.forEach((dado) => {
            testeSimilaridade(`${dado}`);
        });

        return erros;
    },

    gerarTokenApiTelessaude: async (config) => {
        const payload_ = {
            grant_type: 'client_credentials',
            client_id: config.api_telessaude_client_id,
            client_secret: config.api_telessaude_client_secret
        };
        const headers = { 'Content-Type': 'application/json' };
        const { payload } = await Wreck.post(`${config.url_base_api_telessaude}/oauth2/auth/token?grant_type=client_credentials`, { payload: payload_, headers, timeout: 10000 });
        const authorization = JSON.parse(payload.toString());
        return `${authorization['token_type']} ${authorization['access_token']}`;
    },

    removerMascaraNumero: async (inputComMascara) => {
        if (!inputComMascara) {
            return null;
        }
        return inputComMascara.replace(/\D/g, '');
    },

    adicionarMascara: (input, mask, opcoes) => {
        let formatter;
        if (opcoes) {
            formatter = new StringMask(mask, opcoes);
        } else {
            formatter = new StringMask(mask);
        }

        return formatter.apply(input);
    },

    adicionarMascaraContato: (contato, categoria) => {
        switch (categoria) {
            case TIPO_CONTATO.TELEFONE_RESIDENCIAL:
            case TIPO_CONTATO.TELEFONE_COMERCIAL:
                contato = contato.replace(/[^a-zA-Z0-9]/g, '');
                return Util.adicionarMascara(contato, '(00) 0000-0000', { reverse: true });

            case TIPO_CONTATO.TELEFONE_CELULAR:
                contato = contato.replace(/[^a-zA-Z0-9]/g, '');
                return Util.adicionarMascara(contato, '(00) 00000-0000', { reverse: true });

            default:
                return contato;
        }
    },

    trimHtmlCkeditor: (campo) => {
        if (!campo) {
            return null;
        }

        campo = campo.replace(/^(<p>&nbsp;<\/p>)*|(<p>&nbsp;<\/p>)+$/g, '');

        return campo || null;
    }
};

const _geradorIdade = async (dataNascimento) => {
    return Math.floor((new Date() - new Date(dataNascimento).getTime()) / 3.15576e10);
};

module.exports = Util;
