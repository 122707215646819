import * as yup from 'yup';
import dadosPacienteSchema from '../../../../componentes/solicitacao/paciente/validationSchema';
import dadosProcedimentoSchema from '../../../../componentes/solicitacao/procedimento/form/validationSchema';
import dadosJustificativaSchema from '../../../../componentes/solicitacao/justificativa/form/validationSchema';
import dadosAnexosSchema from '../../../../componentes/solicitacao/anexos/form/validationSchema';
import dadosSolicitanteSchema from '../../../../componentes/solicitacao/solicitante/validationSchema';

export default (strings, camposPaciente) => {
    const schema = yup.object().shape({});
    const dadosPaciente = dadosPacienteSchema(strings, camposPaciente);
    const dadosProcedimento = dadosProcedimentoSchema(strings);
    const dadosJustificativa = dadosJustificativaSchema(strings);
    const dadosAnexos = dadosAnexosSchema(strings);
    const dadosSolicitante = dadosSolicitanteSchema(strings);

    return schema
        .concat(dadosPaciente)
        .concat(dadosProcedimento)
        .concat(dadosJustificativa)
        .concat(dadosAnexos)
        .concat(dadosSolicitante);
}