import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

const initializeApp = (config) => {
    global.gConfig = config;

    const domNode = document.getElementById('root');
    const root = createRoot(domNode);
    root.render(<App modulo={'apac'} currentLanguage={'ptbr'} />);
};

Promise.all([
    fetch(`${process.env.PUBLIC_URL}/config/changelog.json`).then(response => response.json()),
    fetch(`${process.env.PUBLIC_URL}/config/config.json`).then(response => response.json())
])
    .then(([changelog, config]) => initializeApp({ ...config, ...changelog }))
    .catch(error => { console.error("Erro ao buscar os arquivos de configurações do frontend", error); });

serviceWorker.unregister();
