import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { temPermissaoRBAC } from '../security/acl';
import { PERMISSOES } from '../common/Constants';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { usuario } from '../signals/index';
import {
    SttHeader,
    SttMainNavigationBar,
    SttSplashScreen,
    SttTranslateHook,
    SttNotificationScreen,
    SttHelpdeskShortcut,
    MateriaisSuporte
} from '@stt-componentes/core';
import alerta from '../signals/alerta';
import { getHeaders } from '../request';

const Menu = () => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    // signals para as tabs futuras
    const submenus = useSignal([]);
    const tabAtual = useSignal(0);
    const openSS = useSignal(false);
    const openMS = useSignal(false);
    const exibirNotificacao = useSignal(false);
    const location = useLocation();
    const navigate = useNavigate();

    // vai ter as tabs, a tab será pesquisar ou seja patch/pesquisa. Caso não esteja logado joga para:    
    useEffect(() => {
        let menuOptions = [];

        //  /nao-autorizado ( OBS A PAGINA INICAL SERÁ A PESQUISA OU SEJA / == /PESQUISA)
        if (!temPermissaoRBAC(usuario, PERMISSOES.PESQUISAR)) {
            navigate(`/nao-autorizado`);
            return;
        }

        exibirNotificacao.value = true;

        if (temPermissaoRBAC(usuario, PERMISSOES.PESQUISAR)) {
            menuOptions = [
                ...menuOptions,
                {
                    id: '/',
                    text: strings.pesquisa,
                    onClick: () => {
                        if (tabAtual.value !== 0) {
                            tabAtual.value = 0
                            alerta.value = {
                                open: true,
                                title: strings.atencao,
                                type: 'alert',
                                message: strings.retornarTelaPesquisa,
                                options: [
                                    {
                                        title: strings.sim,
                                        onClick: () => {
                                            navigate("/");
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            }
                                        }
                                    },
                                    {
                                        title: strings.nao,
                                        onClick: () => {
                                            tabAtual.value = 1
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            }
                                        },
                                    }

                                ],
                                onClose: () => {
                                    tabAtual.value = 1
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                }
                            }

                        } else { navigate("/") }

                    }
                }
            ];
        }

        if (temPermissaoRBAC(usuario, PERMISSOES.SOLICITACAO)) {
            menuOptions = [...menuOptions, {
                id: '/solicitacao',
                text: strings.solicitacao,
                onClick: () => {
                    if (tabAtual.value !== 1) {
                        tabAtual.value = 1;
                        navigate("/solicitacao");
                    }
                }
            }];
        }

        if (temPermissaoRBAC(usuario, PERMISSOES.ADMINISTRACAO)) {
            menuOptions = [...menuOptions, {
                id: '/administrativo',
                text: strings.administrativo,
                onClick: () => {
                    if (tabAtual.value !== 1) {
                        tabAtual.value = 1;
                        navigate("/administrativo");
                    }
                }
            }];
        }

        submenus.value = menuOptions;
    }, []);

    // Salva a aba que o usuário está antes de atualizar a tela
    useEffect(() => {
        submenus.value.forEach((menu, i) => {
            if (location.pathname.includes(menu.id)) {
                tabAtual.value = i;
            }
        });
    }, [location]);

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => openSS.value = !openSS.value,
                    onClickMateriaisSuporte: () => openMS.value = !openMS.value,
                    strings
                }}
            />
            <SttMainNavigationBar
                titulo={strings.tituloModuloApac}
                config={global.gConfig}
                submenus={submenus.value}
                selectedTab={tabAtual.value}
                callbackNavigationBar={(a, b) => { }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                open={openSS.value}
                setOpen={(open) => { openSS.value = open }}
            />
            {
                exibirNotificacao.value &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={usuario.value.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={usuario.value}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS.value}
                setOpen={(open) => { openMS.value = open }}
            />
        </div>
    );
};
export default Menu;
