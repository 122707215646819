import { Strings } from '@stt-utilitarios/core';
import { temPerfilRBAC } from '../security/acl';
import { usuario } from '../signals';
const { apac } = Strings;

export const PERFIL = {
    APAC_SOLICITANTE: 'apacSolicitante',
    APAC_AUTORIZADOR: 'apacAutorizador',
    APAC_ADMINISTRADOR: 'apacAdministrador',
    ADMINISTRADOR: 'administrador',
    APAC_OPERADOR: 'apacOperador'
};

export const PERMISSOES = {
    APAC: 'APAC_APAC',
    PESQUISAR: 'APAC_PESQUISAR',
    SOLICITACAO: 'APAC_SOLICITACAO',
    AUTORIZACAO: 'APAC_AUTORIZACAO',
    ADMINISTRACAO: 'APAC_ADMINISTRACAO'
};

export const situacao = {
    CANCELADO: -5,
    RASCUNHO: 0,
    AGUARDANDO_AVALIACAO_AUTORIZADOR: 5,
    DEVOLVIDO: 10,
    AUTORIZADO: 15,
    AGUARDANDO_ENCAMINHAMENTO_OPERADOR: 20,
    EM_ANALISE: 3,
    AUTORIZADO_PELO_MEDICO_AUTORIZADOR: 30,
    INVALIDADO: -10
};

export const TIPO_SOLICITACAO = {
    PADRAO: 'P',
    CONTINUIDADE: 'C'
}

export const DESCRICAO_TIPO_PROCESSO = (strings) => {
    return {
        [TIPO_SOLICITACAO.PADRAO]: strings.apacPadrao,
        [TIPO_SOLICITACAO.CONTINUIDADE]: strings.apacContinuidade
    }
}

export const formatarSituacaoLabelCorProcesso = () => {
    if (temPerfilRBAC(usuario, PERFIL.APAC_SOLICITANTE)) {
        return {
            SITUACAO: { ...situacao },
            LABEL: {
                [situacao.CANCELADO]: apac.cancelado,
                [situacao.RASCUNHO]: apac.rascunho,
                [situacao.DEVOLVIDO]: apac.devolvido,
                [situacao.AUTORIZADO]: apac.autorizado,
                [situacao.INVALIDADO]: apac.invalidado,
                [situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR]: apac.emAnalise,
                [situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR]: apac.emAnalise,
                [situacao.AUTORIZADO_PELO_MEDICO_AUTORIZADOR]: apac.emAnalise,
                [situacao.INVALIDADO]: apac.invalidado
            },
            COR: {
                [situacao.RASCUNHO]: '#d9d9d9',
                [situacao.CANCELADO]: '#999999',
                [situacao.DEVOLVIDO]: '#f4cbcb',
                [situacao.AUTORIZADO]: '#b0d8b1',
                [situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR]: '#faf7a9',
                [situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR]: '#faf7a9',
                [situacao.AUTORIZADO_PELO_MEDICO_AUTORIZADOR]: '#faf7a9',
                [situacao.INVALIDADO]: '#999999'
            }
        };
    }

    return {
        SITUACAO: { ...situacao },
        LABEL: {
            [situacao.CANCELADO]: apac.cancelado,
            [situacao.RASCUNHO]: apac.rascunho,
            [situacao.DEVOLVIDO]: apac.devolvido,
            [situacao.AUTORIZADO]: apac.autorizado,
            [situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR]: apac.aguardandoAutorizador,
            [situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR]: apac.aguardandoOperador,
            [situacao.AUTORIZADO_PELO_MEDICO_AUTORIZADOR]: apac.autorizadoPeloMedicoAutorizador,
            [situacao.INVALIDADO]: apac.invalidado
        },
        COR: {
            [situacao.RASCUNHO]: '#d9d9d9',
            [situacao.CANCELADO]: '#999999',
            [situacao.DEVOLVIDO]: '#f4cbcb',
            [situacao.AUTORIZADO]: '#b0d8b1',
            [situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR]: '#cee1f4',
            [situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR]: '#fbd7b1',
            [situacao.AUTORIZADO_PELO_MEDICO_AUTORIZADOR]: '#d9ead2',
            [situacao.INVALIDADO]: '#999999'
        }
    };
}

export const formatarSituacaoProcessoFiltro = () => {
    if (temPerfilRBAC(usuario, PERFIL.APAC_SOLICITANTE)) {
        return [
            {
                id: situacao.RASCUNHO,
                descricao: apac.rascunho
            },
            {
                id: situacao.EM_ANALISE,
                descricao: apac.emAnalise
            },
            {
                id: situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR,
                descricao: apac.aguardandoAutorizador
            },
            {
                id: situacao.AUTORIZADO,
                descricao: apac.autorizado
            },
            {
                id: situacao.DEVOLVIDO,
                descricao: apac.devolvido
            },
            {
                id: situacao.CANCELADO,
                descricao: apac.cancelado
            },
            {
                id: situacao.INVALIDADO,
                descricao: apac.invalidado
            }
        ]
    }

    return [
        {
            id: situacao.RASCUNHO,
            descricao: apac.rascunho
        },
        {
            id: situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR,
            descricao: apac.aguardandoOperador
        },
        {
            id: situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR,
            descricao: apac.aguardandoAutorizador
        },
        {
            id: situacao.AUTORIZADO_PELO_MEDICO_AUTORIZADOR,
            descricao: apac.autorizadoPeloMedicoAutorizador
        },
        {
            id: situacao.AUTORIZADO,
            descricao: apac.autorizado
        },
        {
            id: situacao.DEVOLVIDO,
            descricao: apac.devolvido
        },
        {
            id: situacao.CANCELADO,
            descricao: apac.cancelado
        },
        {
            id: situacao.INVALIDADO,
            descricao: apac.invalidado
        }
    ]
}

export const CONSELHO_TRABALHO = {
    CRM: 'CRM'
}

export const CODIGO_PET_SCAN = '0206010095';

export const PROCEDENCIA_PEDIDO = {
    UNACON: 'U',
    JUDICIAL: 'J',
    OUTROS: 'O'
}

export const DESCRICAO_PROCEDENCIA_PEDIDO = (strings) => {
    return {
        [PROCEDENCIA_PEDIDO.UNACON]: strings.numeroAtendimentoUnacon,
        [PROCEDENCIA_PEDIDO.JUDICIAL]: strings.numeroAcaoJudicial,
        [PROCEDENCIA_PEDIDO.OUTROS]: strings.outros,
    }
}

export const TIPO_OPERACAO = {
    CANCELAMENTO: 'CANCELAMENTO',
    DEVOLUCAO: 'DEVOLUCAO',
    AUTORIZACAO: 'AUTORIZACAO',
    ENCAMINHAR_AUTORIZADOR: 'ENCAMINHAR_AUTORIZADOR',
    APROVACAO: 'APROVACAO',
    INVALIDACAO: 'INVALIDACAO'
}

export const TIPO_ANEXO_SIGLA = {
    LAUDO_MEDICO: 'LM',
    EXAME_COMPLEMENTAR: 'EC',
    CONSULTA_REGULADA: 'CR',
    EXAMES_OBRIGATORIOS: 'EO',
    DOCUMENTO_PESSOAL: 'DP',
    PROCESSO_COMPLETO: 'PC',
    HISTORICO_SGPE: 'HS',
    SISREG: 'SI',
    DEMAIS_DOCUMENTOS: 'DD'
}

export const OPCAO_OUTROS_PROCEDIMENTOS = { id: -1, nome: 'Outros' };

export const MYME_TYPE_ARQUIVOS = {
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    PDF: 'application/pdf',
    WORD: 'application/msword',
    EXCEL: 'application/vnd.ms-excel',
    SHEET: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const MYME_TYPE_PERMITIDOS = Object.values(MYME_TYPE_ARQUIVOS);

export const SIGLA_TIPO_ANEXO_AUTORIZACAO_APAC = 'AA';