import React from 'react';
import { temPerfilRBAC , temPermissaoRBAC } from '../../../security/acl';
import { Navigate } from 'react-router-dom';
import { usuario } from '../../../signals';

const ProtectedRoute = ({ component: Component, permissao, perfil }) => {
    if (permissao) {
        return temPermissaoRBAC(usuario, permissao) ? <Component /> : <Navigate replace to='/nao-autorizado' />
    }
    let temPermissao = false;
    if (Array.isArray(perfil)) {
        perfil.forEach(p => {
            if (temPerfilRBAC(usuario, p)) {
                temPermissao = true;
            }
        });
        return temPermissao ? <Component /> : <Navigate replace to='/nao-autorizado' />
    }
    return temPerfilRBAC(usuario, perfil) ? <Component /> : <Navigate replace to='/nao-autorizado' />
};

export default ProtectedRoute;